import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Credits = () => (
  <Layout>
    <Seo title="Credits" />
    <h1>Credits and <b>Thank you</b></h1>
    <p>"rp23" stands for "<a href="https://re-publica.com/en">re:publica 23</a>:". It is the festival for the digital society and takes place from 5-7 June 2023 at Arena Berlin and Festsaal Kreuzberg with the motto "CASH".</p>
    <p>Thank you <a href="https://re-publica.com/de/team">rp23 team</a> (@republica) for the great event.</p>
    <p>Thank you Christen and Thomas (Toto) for the API access provided and your support.</p>
    <p>Thank you Luca Hammer (@luca) for your in-depth feedback.</p>
    <p>Thank you Rolf Mistelbacher (@rolfvanroot) for your in-depth feedback.</p>
    <p>Thank you Hans Hübner (@HansHuebner) for your technical advice.</p>
    <p>Maneki-neko GIF by <a href="https://www.debutart.com/artist/craig-robinson#/illustration">Craig Robinson</a>.</p>
    <p>Site built using Gatsby.</p>
        
  </Layout>
)

export default Credits